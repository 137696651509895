<template>
	<div
		class="alert my-1 mb-2 text-left px-0"
		role="alert">
		<div class="row">
			<div class="col">
				<h4 class="font-weight-heavy mb-2">
					{{ title }}
				</h4>
			</div>
		</div>
		<div class="row">
			<div
				v-if="image.length"
				class="col-12 col-md-8">
				<div class="row">
					<div class="col">
						<div class="card card-image-limit bg-transparent">
							<img
								class="card-img"
								:style="{'max-height': '200px'}"
								:src="image">
						</div>
					</div>
				</div>
			</div>
			<div
				:class="{'mt-3': ['xs', 'sm'].includes(windowWidth), 'col-md-4': image.length }"
				class="col-12">
				<div class="row">
					<div class="col">
						<div class="card card-image-limit bg-transparent">
							<img
								class="card-img"
								:style="{'max-width': '160px', 'max-height': '150px'}"
								:src="mobileImage">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import { Banners as messages } from '@/translations';

export default {
	name: 'BannerItem',
	messages,
	mixins: [WindowSizes],
	props: {
		id: {
			type: Number,
			default: 0,
		},
		title: {
			type: String,
			default: '',
		},
		image: {
			type: String,
			default: '',
		},
		mobileImage: {
			type: String,
			default: '',
		},
	},
	methods: {
		update() {
			this.$emit('update', this.id);
		},
		delete() {
			this.$emit('dalete', this.id);
		},
	},
};
</script>
<style scoped>
.card-image-limit {
	max-height: 250px;
	margin-left: auto;
	margin-right: auto;
	border: none;
}
.card-image-limit img {
	max-width: 100%;
	height: auto;
	width: fit-content;
	margin-left: auto;
	margin-right: auto;
}
</style>
